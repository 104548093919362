import {useCallback,useEffect, useState} from "react";
import MaterialTable, {MTableHeader} from 'material-table';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import axios from "axios";
import {DateTime} from "luxon";
import { useTranslation } from 'react-i18next';

import i18n from '../i18n';
import { useLoginData } from "../contexts/loginContext";

const langTransTypeMapping = {
	en:"valueEn",
	tc:"valueTc",
	sc:"valueSc",
};

const paymentMethodMapping = {
	en:{
    "EP-FPS": "FPS",
    "EP-HSBC": "HSBC / HANG SENG",
    "EP-JET": "JETCO",
    "EP-PPS": "PPS",
    "EP-7Eleven": "7-Eleven",
		"EP-SEVEN_ELEVEN": "7-Eleven",
		"EP-CO_BRAND": "CO BRAND",
    "MP-CASH": "CASH / CHEQUE",
	},
	tc:{
    "EP-FPS": "轉數快",
    "EP-HSBC": "匯豐 / 恆生",
    "EP-JET": "銀通",
    "EP-PPS": "繳費靈",
    "EP-7Eleven": "7-Eleven",
		"EP-SEVEN_ELEVEN": "7-Eleven",
		"EP-CO_BRAND": "CO BRAND",
    "MP-CASH": "現金 / 支票",
	},
	sc:{
    "EP-FPS": "转数快",
    "EP-HSBC": "汇丰 / 恒生",
    "EP-JET": "银通",
    "EP-PPS": "缴费灵",
    "EP-7Eleven": "7-Eleven",
		"EP-SEVEN_ELEVEN": "7-Eleven",
		"EP-CO_BRAND": "CO BRAND",
    "MP-CASH": "现金 / 支票",
	}
}

const useTableHeaderStyles = makeStyles(theme => ({
	header:	{
		"background-color": theme.palette.primary.main,
		"color": theme.palette.primary.text.color, // text color
	}
}));

const usePaymentTransactionIconStyles = makeStyles(theme => ({
	container: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		gap:'8px',
	},
	icon: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.text.color, 
		fontSize: 10,
		padding: 4,
		borderRadius: 4,
		minWidth: 50,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	}
}))

const getData = async (accountId) => {
	let result;

	const postData = {
		accountNum: accountId,
	};

	try{
		const response = await axios({
			method: "post",
			url: `${process.env.REACT_APP_API_ROOT}/history`,
			data: postData,
		});

		if (response?.data?.result?.data?.transactionHistory?.length > 0){
			result = response.data.result.data.transactionHistory;
		}
		return Promise.resolve(result);
	}
	catch(err){
		console.error(err);
		return Promise.reject(result);
	}
}

function ColumnsDef(props){
	const { t } = useTranslation("translation", {i18n});
	const classes = usePaymentTransactionIconStyles()
	
	const columnsDef = [
		{ 
			title: t('history_table_header_date'), field: 'tranTime', type:'date',
			// render: rowData => DateTime.fromFormat(rowData.tranTime, "yyyy-MM-dd hh:mm:ss").toFormat("dd MMM yyyy")
			render: rowData => DateTime.fromFormat(rowData.tranTime, "yyyy-MM-dd hh:mm:ss").toFormat("yyyy-MM-dd") // standardize for all language
		},
		// { title: t('history_type'), field: 'tranType' },
		{ title: t('history_table_header_type'), field: langTransTypeMapping[i18n.language], 
			render: rowData => (
				<div className={classes.container}>
					<div>{rowData[langTransTypeMapping[i18n.language]]}</div>
					{/* adding a fall back for translation if payment method dos not exist, take out the EP- / MP-... */}
					{rowData.paymentMethod ? <div className={classes.icon}>{paymentMethodMapping[i18n.language][rowData.paymentMethod] || rowData.paymentMethod.split("-").slice(1).join("-")}</div> : null}
				</div>
				)
		},
		{ title: t('history_table_header_amount'), field: 'amount', type: 'numeric'},
		// { 
			// title: t('home.tableHeaderFromDate'), field: 'fromDate', type:'date', 
			// render: rowData => moment(rowData.fromDate).format('DD/MM/YYYY')
		// },
		
	];
	return columnsDef;
}

export default function HistoryTable(props){
	const columnsDef = ColumnsDef();
	const [tableData, setTableData] = useState([]);

	const headerClasses = useTableHeaderStyles();
	const { t } = useTranslation("translation", {i18n});

	const [_, updateData] = useLoginData();
	
	const refreshData = useCallback(async () => {
		try {
			if (props.accountId){
				const tmpData = await getData(props.accountId, i18n.language);
				setTableData(tmpData);
			}
		}
		catch (err) {
			console.error(err);
		}
	},[props.accountId, i18n.language]);

	// Effect - Load Data
	useEffect(()=>{
		console.debug(`HistoryTable Effect | get data`);
		refreshData();
	},[refreshData])

	// Effect - 2022-Apr
	useEffect(()=>{
		const getDefaultTariffAmount = async () => {
			console.debug(`HistoryTable Effect | get default tariff amount`);

			try {
				// 2022-Apr: add API call for getting default value for tariff amount
				const resDefaultAmount = await axios({
					method: "post",
					url: `${process.env.REACT_APP_API_ROOT}/getDefaultPaymentAmount`,
				});
				const defaultAmount = resDefaultAmount?.data?.result?.defaultAmount;

				updateData({ defaultAmount:defaultAmount }); // update context for later usage
			}
			catch(err){
				console.debug(`[Error] HistoryTable Effect | get default tariff amount`);
				console.debug(err);
			}
		}
		getDefaultTariffAmount();
	},[])

	return (
		<Grid>
			<Typography variant="h2" component="h2">{t('history_table_title')}</Typography>
			<Typography variant="body1">{t('history_table_subtitle_warning')}</Typography>
			<MaterialTable
				columns={columnsDef}
				data={tableData}
				// title={props.tableTitle}
				options={{
					filtering: false,
					exportButton: false,
					showTitle: false,
					selection: false,
					search: false,
					toolbar: false,
					paging: false,
					// rowStyle: props.rowStyle
				}}
				// to hide header
				components={{
					Header: props => (
						<MTableHeader {...props} classes={headerClasses}/>  
					)
				}}
				localization={{
					body:{
						emptyDataSourceMessage: t('history_table_ui_no_record')
					},
					pagination: {
							labelDisplayedRows: t('history_table_ui_pagination'), // {from}-{to} of {count}
							labelRowsSelect: t('history_table_ui_display_num_rows'),
							firstTooltip: '', // remove
							previousTooltip: '', // remove
							nextTooltip: '', // remove
							lastTooltip: '', // remove
					},
				}}
			/>
		</Grid>
	)
}
import { useMemo, useState } from "react";

import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'

import axios from "axios";

import { useTranslation } from 'react-i18next';

import i18n from '../i18n';

import { useLoginData } from "../contexts/loginContext";
import InfoGridTableLayout from "./common/infoGridTableLayout";
import FpsPaymentQrPage from "./fpsPaymentQrPage";
const breakpoints = createBreakpoints({})

const fieldLabelMapping = (lang) => [
	[
		{
			field: `carparkFacilityName${lang.charAt(0).toUpperCase()}${lang.charAt(1)}`,
			label: 'fps_payment_page_carpark_name_label',
		},
	],
	[
		{
			field: 'lpn',
			label: 'fps_payment_page_license_plate_label',
		},
	],
]

const useFpsPaymentPageStyles = makeStyles(theme => ({
	fpsPageRoot: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		"& > .MuiTypography-root": {
			textAlign: "center",
			// marginBottom: 20,
		},
		"& .MuiButton-root": {
			width: "49%",
		},
		
	},
	infoGridTableLayout: {
		width: "80%",
		alignSelf: "flex-end",
		marginLeft: "auto",
		marginRight: "auto",
		maxWidth: "400px",
		[breakpoints.down('sm')]: {
			textAlign: "center"
		},
	},
	twoButtonsContianer: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		marginTop: 20,
		marginBottom: 20,
	},
	paymentFormContainer: {
		maxWidth: 400,
		width: "100%",
		marginLeft: "auto",
		marginRight: "auto",
		"& .MuiTextField-root": {
			width: "100%",
		},
		"& > .MuiTypography-body1": {
			marginBottom: "1rem"
		},
		"& .MuiFormHelperText-root.Mui-error" : {
			marginLeft: 0
		}
	},
	reminderText: {
		[breakpoints.down('sm')]:{
			fontSize: "initial"
		}
	}
}));

// call API to check the data input (is it > 1 and < MAx threshold )
const getData = async (amount, accountId) => {
	const postData = {
		accountNum: accountId,
		amount: amount
	};

	try {
		const response = await axios({
			method: "post",
			url: `${process.env.REACT_APP_API_ROOT}/validatePaymentAmount`,
			data: postData,
		});

		if (response?.data?.result?.value) {
			return Promise.resolve(true);
		}
		else { // error code
			return Promise.reject(response.data.error_code)
		}
	}
	catch (err) {
		console.error(err);
		return Promise.reject(err);
	}
}

/*
	props:
	 - acocuntId
	 - handleClose
*/
export default function FpsPaymentPage(props) {
	const classes = useFpsPaymentPageStyles();
	const { t } = useTranslation("translation", { i18n });
	const [data] = useLoginData();

	// default value = matched account's monthly Amount
	// User request monthly amount => need to be string for API input
	const [amount, setAmount] = useState(`${data.accounts.filter(item => item.accountID === props.accountId)[0].monthlyAmount}`);

	// update 2022-Apr => need to have a default Amount => Obsoleted, user want to have user's item's amount
	// const [amount, setAmount] = useState(data.defaultAmount);
	const [isQrPageOpen, setIsQrPageOpen] = useState(false);
	const [buttonEnabled, setButtonEnabled] = useState(true);
	const [errMessageKey, setErrMessageKey] = useState(null);
	const [haveError, setHaveError] = useState(false)
	const fieldLabelMappingObj = useMemo(()=>{console.log(`change lang ${i18n.language}`);return fieldLabelMapping(i18n.language)},[i18n.language]);
	// console.log(fieldLabelMappingObj);


	// pass to <FpsPaymentQrPage />
	const handleQrPageClose = (e) => {
		setIsQrPageOpen(false);
	}

	const handleAmountChange = (e) => {
		setHaveError(false);
		setErrMessageKey(null);
		setAmount(e.target.value.replace(/[^0-9]/g, '')); // as requested by Link in email on 2022-05-17, limit to integer only
	};

	const handleQrPageOpen = (e) => {
		setButtonEnabled(false);
		// console.log(props)
		getData(amount, props.accountId)
			.then(response => { // response is bool true always
				setButtonEnabled(true); // end of API call
				
				setIsQrPageOpen(true);
			})
			.catch(errCode => {
				setHaveError(true);
				setButtonEnabled(true);				
				if (errCode === "B00001") setErrMessageKey('error_payment_amount');
				else if (errCode === "B00002") setErrMessageKey('error_payment_too_many_times');
				else setErrMessageKey('error_payment_unknown');
			});
	}

	// to inject amount into the object to fulfill the expected object of <InfoGridTableLayout/>
	const fpsData = props.data.map(item => ({ ...item, payAmount: amount, payAmountStr: `$${amount}` }));

	return (
		isQrPageOpen ? <FpsPaymentQrPage {...props} onClose={handleQrPageClose} onBackToHistory={props.onClose} data={fpsData} /> : (
			<Grid className={classes.fpsPageRoot}>
				<Typography variant="h1" component="h1">{t('fps_payment_page_title')}</Typography>
				{/* use accounts[0] as all accounts share the same phone# and license plate*/}
				<Grid className={classes.infoGridTableLayout}>
					<InfoGridTableLayout fieldLabelMapping={fieldLabelMappingObj} data={{
						// mobileNum: data.accounts[0].telephoneNo, licensePlate: data.accounts[0].lpn
						...(data.accounts[0])
					}} />
				</Grid>
				<Grid className={classes.paymentFormContainer}>
					<Typography className={classes.reminderText} variant="body1">{t('fps_payment_page_subtitle')}</Typography>
					<TextField
						label={t('fps_payment_page_input_amount_label')}
						id="fps-payment-page-amount-field"
						InputProps={{ // empty to lift up the label
							startAdornment: <InputAdornment position="start">{"HK$"}</InputAdornment>,
						}}
						value={amount || ""}
						variant="outlined"
						helperText={t(errMessageKey)}
						error={haveError}
						onChange={handleAmountChange}
					/>
					<Grid className={classes.twoButtonsContianer}>
						<Button variant="contained" color="primary" disabled={!buttonEnabled} onClick={props.onClose}>{t("fps_payment_page_back")}</Button>
						<Button variant="contained" color="secondary" disabled={!buttonEnabled} onClick={handleQrPageOpen}>{t("fps_payment_page_confirm")}</Button>
					</Grid>
				</Grid>

			</Grid>
		)
	)
};
import Grid from '@material-ui/core/Grid';
import HtmlContent from '../components/common/htmlContent';

import makeStyles from '@material-ui/core/styles/makeStyles';

const useFpsStepsStyles = makeStyles((theme) => ({
	// common
	fpsStepsRoot: {
		marginTop: 20,
		marginBottom: 20,
		marginLeft: 20,
		marginRight: 20,
		borderWidth: 1,
		borderStyle: "solid",
		padding: "0.5rem 0.75rem"
	}
}));

export default function FpsSteps(props) {
	const classes = useFpsStepsStyles();
	return (
		<Grid className={classes.fpsStepsRoot}>
			<HtmlContent cmsKey={"fps_steps_instruction"}/>
		</Grid>
	)
}
import React from "react";

const CmsDataContext = React.createContext();

function useCmsData() {
  const context = React.useContext(CmsDataContext);
  if (!context) {
    throw new Error(`useCmsData must be used within a CmsDataProvider`)
  }
  return context;
}

function CmsDataProvider(props) {
  const [data, setData] = React.useState({}); // empty object
  const updateData = (newData) => {
    setData(prevDataState => ({...prevDataState, ...newData}));
  }
  const value = React.useMemo(() => [data, updateData], [data])
  return <CmsDataContext.Provider value={value} {...props} />
}

export {CmsDataProvider, useCmsData}
import { useCallback, useEffect, useRef, useState } from "react";
import makeStyles from '@material-ui/core/styles/makeStyles';
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import PrintIcon from '@material-ui/icons/Print';
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CloseIcon from '@material-ui/icons/Close';
import InfoGridTableLayout from "./common/infoGridTableLayout";
import { useTranslation } from 'react-i18next';

import i18n from '../i18n';
import { useLoginData } from "../contexts/loginContext";
import { conformsTo } from "lodash";

const fieldLabelMapping = (lang) => [
	[
		{
			field: `facilityName.${lang}`,
			label: 'fps_payment_qr_page_carpark_name_label',
		}
	],
	[
		{
			field: 'licensePlate',
			label: 'fps_payment_qr_page_license_plate_label',
		}
	],
]

const usePaymentCompletePageStyles = makeStyles(theme => ({
	fpsCompletePageRoot: {
		display: "flex",
		flexDirection: "column",
		alignContent: "center",
		"& > .MuiTypography-root": {
			textAlign: "center",
		},
	},
	infoGridTableLayout: {
		display: "none",
		width: "80%",
		marginLeft: "auto",
		marginRight: "auto",
		alignSelf: "flex-end",
		"& > .infoGridRoot": {
			textAlign: "center"
		},
		"@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)" : {
			textAlign: "center"
		}
	},
	button: {
		margin: 10,
		marginBottom: 0,
		color: "black"
	},
	buttonIcon: {
		// should use mediaQuery
		fontSize: "2em",
	},
	qrcodeDiv: {
		// padding:80,
		width: "50%",
		height: "calc(100vw * 0.4)",
		// textAlign: "center",
		backgroundRepeat: "no-repeat",
		backgroundSize: "cover",
		alignSelf: "center",
		"@media print": {
			width: 100
		},
	},
	buttonDivContainer: {
		display: "flex",
		justifyContent: "center",
		"& > .MuiGrid-root": {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
		},
		"@media print": { //tablet
			display: "none"
		},
	},
	checkIcon: {
		color: "green",
		width: 100,
		height: 100,
		margin: "auto",
		marginBottom: "1rem"
	}
}));

/*
	props:
		amount
		txnRef
		onClose - function (this should be passed onClose from PS Payment page root so this would go back to History page)
*/
export default function FpsPaymentCompletePage(props) {
	const classes = usePaymentCompletePageStyles();
	const { t } = useTranslation("translation", { i18n });
	const [, , , logout] = useLoginData();

	const handlePrintCompleteForm = (e) => {
		// const printContents = document.getElementById("fpsCompletePage").innerHTML;
		window.print();
	};

	return (
		<Grid id="fpsCompletePage" className={classes.fpsCompletePageRoot}>
			<Typography variant="h2" component="h1">{t('fps_payment_complete_page_title')}</Typography>
			<Grid className={classes.infoGridTableLayout}>
				<InfoGridTableLayout fieldLabelMapping={fieldLabelMapping(i18n.language)} data={props.data} />
			</Grid>
			<CheckCircleIcon className={classes.checkIcon} />
			<Typography variant="h2">{t('fps_payment_complete_page_subtitle')}</Typography>
			<Typography variant="body2">{t('fps_payment_complete_page_you_have_sent')}</Typography>
			<Typography variant="body2">{`HKD ${props.amount} ${t('fps_payment_complete_page_to_merchant_link')}`}</Typography>
			<Typography variant="body2">{`${t('fps_payment_complete_page_transaction_ref')} ${props.txnRef}`}</Typography>
			<Grid className={classes.buttonDivContainer}>
				<Grid>
					<IconButton className={classes.button} disableRipple={true} onClick={handlePrintCompleteForm}>
						<PrintIcon className={classes.buttonIcon} />
					</IconButton>
					<Typography variant="body1">{t('fps_payment_complete_page_button_print')}</Typography>
				</Grid>
				<Grid>
					<IconButton className={classes.button} disableRipple={true} onClick={props.onClose}>
						<CloseIcon className={classes.buttonIcon} />
					</IconButton>
					<Typography variant="body1">{t('fps_payment_complete_page_button_close')}</Typography>
				</Grid>
			</Grid>
		</Grid>
	)
}


// Import React related
import { useState, useEffect } from "react";

import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Box from '@material-ui/core/Box';
import TextField from "@material-ui/core/TextField";
import Checkbox from '@material-ui/core/Checkbox';
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControlLabel from '@material-ui/core/FormControlLabel';

// Import other modules
import axios from "axios";
import { useTranslation } from 'react-i18next';

// Import custom developed modules
import i18n from '../i18n';
import { useLoginData } from "../contexts/loginContext";
import TermsAndConditions from "./termsAndConditions";
import CountDownMessage from "./common/countDownMessage";

// const mobileNumRegex = /^[5,6,7,9]\d{7}$/;
const mobileNumRegex = /^[4,5,6,7,8,9]\d{7}$/; // update as mobile number already can start with 4,7,8

const useLoginStyles = makeStyles((theme) => ({
	// common
	loginPageRoot: {
		display: "block",
		justifyContent: "center",
		textAlign: "center",
		"& > .MuiGrid-container": { // Grid container under root
			justifyContent: "center",
		},
		"& .MuiButton-root": {
			width: "49%",
		},
		"& > * > .MuiTypography-root": {
			marginTop: 12,
			marginBottom: 12,
		},
		"& > h1": {
			fontWeight: 600,
			marginBottom: "1rem"
		},
		"& .MuiFormHelperText-root.Mui-error": {
			marginLeft: "0.125rem",
			color: "#f44336",
			textAlign: "left"
		},
		"& .MuiCheckbox-root": {  //checkbox
			paddingTop: 2,
    		paddingBottom: 2
		},
		"& .MuiButton-root": {
			marginTop: "1rem",
			width: "100%"
		},

	},
	loginFormContainer: {
		width: "100%",
		maxWidth: 400,
		marginLeft: "auto",
		marginRight: "auto"
	},
	containerTextFields: {
		width: "100%",
		display: "flex",
		flexDirection: "column",
		"& > .MuiTextField-root": {
			marginTop: 20,
			marginBottom: 10,
		},
		"& > .MuiGrid-item": {
			padding: 0,
		}
	},
	// Login Mobile/License
	checkboxGridItem: {
		textAlign: "left",
	},
	// OTP
	twoButtonsContianer: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
	},
	twoButtonsContianerBtn: {
		width: "49%",
		marginLeft: "0.25rem",
		marginRight: "0.25rem"
	}

}));

/*
props:
	- tcAgreed
	- errorStatus
	- buttonEnabled
	- handleSubmit
	- handleMobileNumChange
	- handleLicensePlateChange
	- handleTermsCheckboxChange
	- mobileNum
	- licensePlate
*/
const LoginStageMobileLicense = (props) => {
	const classes = useLoginStyles();
	// internationalization
	const { t } = useTranslation("translation", { i18n });

	return (
		<>		
			<Typography variant="body1">{t('login_mobile_license_plate_subtitle')}</Typography>
			<Grid className={classes.containerTextFields}>
				<TextField
					label={t("login_mobile_no")}
					id="login-mobile-field"
					InputProps={{
						startAdornment: <InputAdornment position="start">+852</InputAdornment>,
					}}
					variant="outlined"
					onChange={props.handleMobileNumChange}
					helperText={t(props.errorStatus.mobileNum.message)}
					error={props.errorStatus.mobileNum.error}
					value={props.mobileNum}
				/>
				<TextField
					label={t("login_license_plate")}
					id="login-license-plate-field"
					InputProps={{ // empty to lift up the label
						startAdornment: <InputAdornment position="start"> </InputAdornment>,
					}}
					variant="outlined"
					onChange={props.handleLicensePlateChange}
					helperText={t(props.errorStatus.licensePlate.message)}
					error={props.errorStatus.licensePlate.error}
					value={props.licensePlate}
				/>
			</Grid>
			<Grid  className={classes.checkboxGridItem}>
				<FormControlLabel
					control={
						<Checkbox
							checked={props.tcAgreed}
							onChange={props.handleTermsCheckboxChange}
							name="login-terms-and-conditions-checkbox"
							color="primary"
						/>
					}
					label={
						<Typography variant="body2">{t("login_agree_terms_and_conditions")}</Typography>
					}
				/>
			</Grid>
			{props.errorStatus.form.message ? <Grid className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error">{t(props.errorStatus.form.message)}</Grid>: null}
			<Button variant="contained" color="primary" disabled={!props.buttonEnabled} onClick={props.handleSubmit}>{t("login_submit_button")}</Button>
		</>
	)
}

/*
	props:
	- expireTime
	- count
	- errorMsgKey
*/
const OtpMessage = (props) => {
	
	return (
		<CountDownMessage 
			{...props}
			logPrefix="OtpMessage"
			remainCmsTimeKey="login_otp_remaining_time"
			expireCmsTimeKey="login_otp_expired"
		/>
	)
}

/*
	props:
	- genOtpExpireTime
	- genOtpCount
	- buttonEnabled
	- otpErrorMsg
	- handleOtpChange
	- handleGenerateOtp
	- handleVerifyOtp
*/
const LoginStageOtp = (props) => {
	const classes = useLoginStyles();
	// internationalization
	const { t } = useTranslation("translation", { i18n });

	return (
		<>
			<Typography variant="body1">{t('login_otp_subtitle')}</Typography>
			<Grid className={classes.containerTextFields}>
				<TextField
					label={t("login_otp")}
					id="login-otp-field"
					InputProps={{
						startAdornment: <InputAdornment position="start"> </InputAdornment>,
					}}
					variant="outlined"
					onChange={props.handleOtpChange}
				/>
			</Grid>
			<OtpMessage 
				expireTime={props.genOtpExpireTime} 
				count={props.genOtpCount}
				errorMsgKey={props.otpErrorMsgKey}
			/>
			<Grid className={classes.twoButtonsContianer}>
				<Button variant="contained" color="secondary" disabled={!props.buttonEnabled} className={classes.twoButtonsContianerBtn} onClick={props.handleGenerateOtp}>{t("login_generate_otp_button")}</Button>
				<Button variant="contained" color="primary" disabled={!props.buttonEnabled} className={classes.twoButtonsContianerBtn} onClick={props.handleVerifyOtp}>{t("login_verify_otp_button")}</Button>
			</Grid>
		</>
	)
}

const Login = (props) => {
	const urlSearchParams = new URLSearchParams(window.location.search);
	const mobileNoParam = urlSearchParams.get("mobile");
	const lincesePlateParam = urlSearchParams.get("license");

	const [, updateLoginData] = useLoginData();

	const [submitButtonEnabled, setSubmitButtonEnabled] = useState(true);
	const [mobileNum, setMobileNum] = useState(mobileNoParam);
	const [licensePlate, setLicensePlate] = useState(lincesePlateParam);
	const [tcAgreed, setTcAgreed] = useState(false);
	const [stageMobileLicenseFieldErrorStatus, setStageMobileLicenseFieldErrorStatus] = useState({
		form:{message:""}, // form level
		checkbox:{message:""}, // no error state
		licensePlate:{message:"", error:false},
		mobileNum:{message:"", error:false},
	});

	const [otpButtonsEnabled, setOtpButtonsEnabled] = useState(true); // for both buttons
	const [otp, setOtp] = useState("");
	const [isOtpPageOpen, setOtpPageOpen] = useState(false);
	const [genOtpExpireTime, setgenOtpExpireTime] = useState(null);
	const [genOtpCount, setGenOtpCount] = useState(0);
	const [otpErrorMsgKey, setOtpErrorMsgKey] = useState("");

	useEffect(()=>{
		setMobileNum(mobileNoParam);
		setLicensePlate(lincesePlateParam);
	},[mobileNoParam,lincesePlateParam])

	const { t } = useTranslation("translation", { i18n });

	const classes = useLoginStyles();

	const handleMobileNumChange = (e) => {
		setMobileNum(e.target.value && e.target.value.trim());
	};

	const handleLicensePlateChange = (e) => {
		setLicensePlate(e.target.value && e.target.value.trim());
	};

	const handleTermsCheckboxChange = (e) => {
		setTcAgreed(e.target.checked);
	};

	const handleOtpChange = (e) => {
		setOtp(e.target.value && e.target.value.trim());
	};

	/*
		Validate submit for stage mobile num and license plate
	*/
	const validateMobileNumLicesencePlate = () => {
		let errflag = false;
		// validation
		let state = {
			form:{message:""}, // form level
			licensePlate:{message:"", error:false},
			mobileNum:{message:"", error:false},
		}

		if (!mobileNum){
			errflag = true;
			state.mobileNum = {message:"error_required_field", error:true};
		}
		if (!licensePlate){
			errflag = true;
			state.licensePlate = {message:"error_required_field", error:true};
		}
		if (mobileNum && !mobileNum.match(mobileNumRegex)){
			errflag = true;
			state.mobileNum = {message:"error_mobile_num_pattern_incorrect", error:true};
		}
		if (!tcAgreed){
			errflag = true;
			state.form = {message:"error_agree_terms_and_conditions_not_checked", error:true};
		}

		setStageMobileLicenseFieldErrorStatus(state);
		return !errflag;
	}

	/*
		1. Call Login API
		2. add return Access Token and Refresh Token into local storage
		*** Not yet complete the login as we need the OTP stage
	*/
	const handleSubmit = async () => {
		// validate first, then API call
		if (validateMobileNumLicesencePlate()){
			const postData = {
				mobileNum: mobileNum,
				licensePlate: licensePlate,
			};

			window.localStorage.setItem('mobileNum', mobileNum);
			window.localStorage.setItem('licensePlate', licensePlate);

			try{
				setSubmitButtonEnabled(false);
				const result = await axios({
					method: "post",
					url: `${process.env.REACT_APP_API_ROOT}/login`,
					data: postData,
				});

				if (result?.data?.result) {
					// const { accessToken, refreshToken, accounts } = result.data.result;
					const { accessToken, accounts } = result.data.result; // refresh token would be send AFTER OTP

					window.localStorage.setItem("accessToken", accessToken);
					// window.localStorage.setItem("refreshToken", refreshToken);  // refresh token moved to verify otp

					handleGenerateOtp();

					updateLoginData({ accounts: accounts }); // update context for later usage
					window.localStorage.setItem("accounts", JSON.stringify(accounts));
					setOtpPageOpen(true);
				}
				else {
					// error login
					let state = {
						form:{message:""}, // form level
						licensePlate:{message:"", error:false},
						mobileNum:{message:"", error:false},
					}
					// generic error
					if (result?.data?.error_code === "A00000") state.form = {message:"error_login_generic", error:true};
					// rate limit reached
					else if (result?.data?.error_code === "A00099") state.form = {message:"error_login_rate_limit", error:true};
					else state.form = {message:"error_login_mobile_license_page", error:true};		
					setStageMobileLicenseFieldErrorStatus(state);				
				}
			}
			catch(err){
				console.error(err);
				let state = {
					form:{message:""}, // form level
					licensePlate:{message:"", error:false},
					mobileNum:{message:"", error:false},
				}
				state.form = {message:"error_login_generic", error:true};
				setStageMobileLicenseFieldErrorStatus(state);	
			}
			finally{
				setSubmitButtonEnabled(true);
			}
		}
	};

	/*
		1. Call Verify OTP API
	*/
	const handleVerifyOtp = async () => {
		const postData = {
			mobileNum: mobileNum,
			licensePlate: licensePlate,
			otp: otp,
		};

		try{
			setOtpButtonsEnabled(false);
			const result = await axios({
				method: "post",
				url: `${process.env.REACT_APP_API_ROOT}/ver_otp`,
				data: postData,
			});

			if (result?.data?.result) {
				const { accessToken, refreshToken } = result.data.result;
				window.localStorage.setItem("accessToken", accessToken);
				window.localStorage.setItem("refreshToken", refreshToken);

				updateLoginData({ loggedIn: true }); // update context so redirect to other page
				window.localStorage.setItem("loggedIn",true);
			}
			else if (result?.data?.error_code){
				if(result.data.error_code === "O10002"){
					// OTP failed verify X times
					setOtpErrorMsgKey('login_otp_verify_failed_too_many_times');
				}
				else if(result.data.error_code === "O10003"){
					// OTP token verify failed
					setOtpErrorMsgKey('login_otp_verify_failed');
				}
				else {
					// general error
					setOtpErrorMsgKey('login_otp_verify_general_error');
				}
			}
		}
		catch(err){
			console.error(err);
			setOtpErrorMsgKey('login_otp_verify_general_error');
		}
		finally{
			setOtpButtonsEnabled(true);
		}
	};

	/*
		1. Call Generate OTP API, generate a token and send thru SMS
	*/
	const handleGenerateOtp = async () => {
		const postData = {
			mobileNum: mobileNum,
			licensePlate: licensePlate,
		};

		try{
			setOtpButtonsEnabled(false);
			const result = await axios({
				method: "post",
				url: `${process.env.REACT_APP_API_ROOT}/gen_otp`,
				data: postData,
			});

			// Handle message display
			if (result?.data?.result){
				// generate code
				if (result?.data?.result?.actualExpireTime) {
					const delta = Math.floor((result.data.result.actualExpireTime - (new Date()).getTime())/1000);
					setGenOtpCount(prev => prev+1);
					setgenOtpExpireTime(delta);
					setOtpErrorMsgKey(null);
				}
			}
			else if (result?.data?.error_code){
				if(result.data.error_code === "O00002"){
					// OTP failed verify X times
					setOtpErrorMsgKey('login_otp_verify_failed_too_many_times');
				}
				else if(result.data.error_code === "O00003"){
					// OTP sent beyond X times
					setOtpErrorMsgKey('login_otp_generate_too_many_times');
				}
				else {
					// general error
					setOtpErrorMsgKey('login_otp_generate_general_error');
				}
			}
		}
		catch(err){
			console.error(err);
			setOtpErrorMsgKey('login_otp_generate_general_error');
		}
		finally{
			setOtpButtonsEnabled(true);
		}
	};

	return (
		<>
			<Grid container className={classes.loginPageRoot}>
				<Typography variant="h1" component="h1" color="primary">{t('login_title')}</Typography>
				<Grid className={classes.loginFormContainer}>
					<Typography variant="h4" component="h2" >
						<Box fontWeight={600}>{t('login_subtitle')}</Box>
					</Typography>
					{isOtpPageOpen ?
						<LoginStageOtp
							genOtpExpireTime={genOtpExpireTime}
							genOtpCount={genOtpCount}
							buttonEnabled={otpButtonsEnabled}
							otpErrorMsgKey={otpErrorMsgKey}
							handleOtpChange={handleOtpChange}
							handleGenerateOtp={handleGenerateOtp}
							handleVerifyOtp={handleVerifyOtp}
						/> :
						<LoginStageMobileLicense
							tcAgreed={tcAgreed}
							errorStatus={stageMobileLicenseFieldErrorStatus}
							buttonEnabled={submitButtonEnabled}
							handleMobileNumChange={handleMobileNumChange}
							handleLicensePlateChange={handleLicensePlateChange}
							handleTermsCheckboxChange={handleTermsCheckboxChange}
							handleSubmit={handleSubmit}
							mobileNum={mobileNum}
							licensePlate={licensePlate}
						/>}
				</Grid>
			</Grid>
			<TermsAndConditions />
		</>
	);
};

export default Login;
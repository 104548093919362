import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ChangeLanguage from "./changeLanguage";

import { useTranslation } from 'react-i18next';
import i18n from '../i18n';

import { useLoginData } from "../contexts/loginContext";

const useTopBarStyle = makeStyles(theme => ({
	bar:{
		backgroundColor: "white",
		height: 70,
		padding: "5px 40px",
		textAlign: "center",
		display: "flex",
		marginLeft: 0,
		marginRight: 0,
	},
	wrapper:{
		maxWidth: 1220,
		margin: "0 0 auto 0",
		display: "flex",
		flexDirection: "row",
		justifyContent:"space-between",
		position: "relative",
		marginLeft: "auto",
		marginRight: "auto",
		width: "100%",
		//backgroundColor: "yellow"
	},
	logoWrapper:{
		height: "100%",
	},
	logo:{
		margin: 5,
		backgroundImage: "url('linkLogo.svg')",
		/*backgroundSize: "55px 55px",*/
		width: 55,
		height: 55,
		backgroundRepeat: "no-repeat",
		backgroundPosition: "center center",
		backgroundSize: "contain"
	},
	changeLanguageWrapper:{
		alignSelf:"flex-end",
		// paddingBottom: 15,
		marginTop: "auto",
		marginBottom: "auto",
		// backgroundColor: "red",
	},
	userGuideLinkWrapper:{
		marginLeft:"auto",
		marginRight:10,
		marginTop:"auto",
		marginBottom:"auto",
	},
	userGuideLink:{
		textDecoration: "none",
		color: theme.palette.primary.main,
		"&:visited":{
			textDecoration: "none"
		},
	},
	logoutContainer:{
		marginTop:"auto",
		marginBottom:"auto",
	},
	button:{		
		// margin: 10,
		// color: "black",
		// marginBottom: 0	
		padding:0,
	},
	buttonIcon:{
		// should use mediaQuery
		// fontSize: "2em"
		marginLeft: 8,
		color: theme.palette.primary.main
	},
}));

const linkUrls = {
	en: "/SiteAssets/CarparkPayment/FPS%20ePayment%20Handbook_EN.pdf",
	tc: "/SiteAssets/CarparkPayment/FPS%20ePayment%20Handbook_TC.pdf",
	sc: "/SiteAssets/CarparkPayment/FPS%20ePayment%20Handbook_SC.pdf",
}

export default function TopBar(props){
	const classes = useTopBarStyle();
	const { t } = useTranslation("translation", {i18n});

	const currentLang = localStorage.getItem("i18nextLng")||"en";
	const linkUrl = linkUrls[currentLang];

	const [, , isLoggedIn, logout ] = useLoginData();

	return (
		<AppBar className={classes.bar}>
			<Grid className={classes.wrapper}>
				<Grid className={classes.logoWrapper}>
					<a href="http://linkhk.com" target="_blank" rel="noreferrer">
						<Grid className={classes.logo}/>
					</a>
				</Grid>
				<Grid className={classes.userGuideLinkWrapper}>
					<a className={classes.userGuideLink} href={linkUrl} target="_blank" rel="noreferrer">
						{t('topbar_user_guide')}
					</a>
				</Grid>
				<Grid className={classes.changeLanguageWrapper}>
					<ChangeLanguage />
				</Grid>
				{window.localStorage.getItem("loggedIn") ? <Grid className={classes.logoutContainer}>
					<IconButton className={classes.button} disableRipple={true} onClick={logout}>
						<ExitToAppIcon className={classes.buttonIcon} />
					</IconButton>
				</Grid> : null}
			</Grid>
		</AppBar>
	)
}
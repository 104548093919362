import Skeleton from '@material-ui/lab/Skeleton';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useSkeletonStyle = makeStyles(theme => ({
	skeletonRoot:{
		"& > .MuiSkeleton-root":{
			marginBottom: 5,
		}
	},
}));

export default function PreLoadingSkeleton(){
	const classes = useSkeletonStyle();
  return (
		<div className={classes.skeletonRoot}>
		<Skeleton animation="wave" />
		<Skeleton animation="wave" />
		<Skeleton animation="wave" />
		<Skeleton animation="wave" />
		</div>
	)
}
import { useEffect, useRef, useState } from "react";
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import html2canvas from 'html2canvas';

import axios from "axios";
import Canvg from "canvg";

import { useTranslation } from 'react-i18next';

import i18n from '../i18n';

import { useLoginData } from "../contexts/loginContext";

import InfoGridTableLayout from "./common/infoGridTableLayout";

const breakpoints = createBreakpoints({});

const fieldLabelMapping = (lang) => ([
	[
		{
			field: `facilityName.${lang}`,
			label: 'seven_eleven_page_carpark_name_label',
		}
	],
	[
		{
			field: 'licensePlate',
			label: 'seven_eleven_page_license_plate_label',
		}
	]
]);

const useSevenElevenStyles = makeStyles(theme => ({
	sevenElevenRoot:{
		display:"flex",
		flexDirection:"column",
		alignContent:"center",
		"& > .MuiTypography-root":{
			textAlign:"center",
		},
		margin: 10,
	},
	infoGridTableLayout:{
		width:"80%",
		alignSelf: "flex-end",
		marginLeft: "auto",
		marginRight: "auto",
		maxWidth: "400px",
		[breakpoints.down('sm')]:{
			textAlign: "center",
		},
	},
	button:{		
		margin: 10,
		color: "black",
		marginBottom: 0
	},
	buttonIcon:{
		// should use mediaQuery
		fontSize: "2em"
	},
	barcodeDiv:	{
		// borderStyle: "solid", // for debug
		// padding:80,
		width: "100%",
		height: "calc(100vw * 0.4)",
		// textAlign: "center",
		// backgroundRepeat:"no-repeat", // temp disable for debug
		backgroundSize: "cover", // this is mainly for IE		
		marginLeft: "auto",
		marginRight: "auto",
		"@media (min-width: 767px)": { //tablet
			width: "65%",
			height: "26vw",
		},
		"@media (min-width: 1224px)": { //desktop or laptop
			width: "68%",
			height: "20vw",
		},
	},
	buttonDivContainer: {
		display: "flex",
		justifyContent: "center",
		"& > .MuiGrid-root":{
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
		},
	},
	reminderText: {
		[breakpoints.down('sm')]:{
			fontSize: "initial"
		}
	}
}));

// call API to get the 7-11 barcode
const getData = async (accountId)=>{
	let result;

	const postData = {
		accountNum: accountId,
	};

	try{
		const response = await axios({
			method: "post",
			url: `${process.env.REACT_APP_API_ROOT}/barcode`,
			data: postData,
		});

		if (response?.data?.result?.qr && response?.data?.result?.text){
			result = response.data.result;
		}
		return Promise.resolve(result);
	}
	catch(err){
		console.error(err);
		return Promise.reject(result);
	}
}

const showFile = (blob) => {
  // It is necessary to create a new blob object with mime-type explicitly set
  // otherwise only Chrome works like it should
  var newBlob = new Blob([blob], {type: "image/png"});

  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob);
    return;
  }

  // For other browsers: 
  // Create a link pointing to the ObjectURL containing the blob.
  const data = window.URL.createObjectURL(newBlob);

  var link = document.createElement('a');
  link.href = data;
  link.download="barcode.png";
  link.click();
  setTimeout(function(){
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(data);
  }, 100);
}

/*
	props:
		accountId -str
		onClose - function
*/
export default function SevenElevenPage(props){
	const ref = useRef(null);
	const [imgBlob, setImgBlob] = useState(null);
	
	const classes = useSevenElevenStyles();
	const { t } = useTranslation("translation", {i18n});

	const [, , , logout ] = useLoginData();

	const isIE = window.navigator && window.navigator.msSaveOrOpenBlob;
	const isIOS = (/iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) && !window.MSStream;


	const download = () => {
		//showFile(imgBlob);

		// console.log(`${window.innerWidth} x ${window.innerHeight}`);
		// html2canvas test
		const element = document.getElementById("SevenElevenDownload");
		// console.log(`${element.offsetWidth} x ${element.offsetHeight}`);
		// console.log(`${element.scrollWidth} x ${element.scrollHeight}`);
		// console.log(element);
		if (isIE){
			const html2canvasOptions = {
				// allowTaint:false,
				useCORS:true,
				backgroundColor: "#ffffff", // needed for IE
				// foreignObjectRendering: true, // this need to be removed, otherwise IE return security error
				windowWidth: element.scrollWidth+25,
				windowHeight:element.scrollHeight+20,
				width:element.scrollWidth+25,
				height:element.scrollHeight+20,
				x:0,
				y:0,
				scrollX:0,
				scrollY:0,
			};
			html2canvas(element, html2canvasOptions).then(canvas => {
				// element.appendChild(canvas);
				// console.log(canvas);
				let showBlob;

				showBlob = canvas.msToBlob("image/jpg");
				showFile(showBlob);
			});

		}
		// ditch another solution below
		else if(true||isIOS) {		
			// console.log(`width: ${element.scrollWidth} | height: ${element.scrollHeight}`)
			let customScale;
			element.scrollHeight > element.scrollWidth ? customScale = 2: customScale = 1;
			const html2canvasOptions = {
				allowTaint:true,
				// useCORS:true,
				// backgroundColor: "transparent",
				backgroundColor: "white",
				// foreignObjectRendering: true, // this is need for modern browsers
				windowWidth: (element.scrollWidth+30)*customScale,
				windowHeight: (element.scrollHeight+100)*customScale,
				width: (element.scrollWidth+30)*customScale,
				height: (element.scrollHeight+100)*customScale,
				x:0,
				y:0,
				scrollX:0,
				scrollY:20,
				scale: 2,
				onclone: (doc)=>{
					// approach 1 => not working as some content might be cropped					
					/*
					console.debug("iOS download");
					const barcodeDiv = doc.getElementById("barcodeDiv");
					barcodeDiv.style.backgroundImage = barcodeDiv.style.content; // html2canvas only support background-image
					barcodeDiv.style["-webkit-background-size"] = "cover";
					barcodeDiv.style.width = "95%";
					// barcodeDiv.style.backgroundSize = "50vmax 100vmax";
					barcodeDiv.style.backgroundRepeat = "no-repeat";

					const downloadRoot = doc.getElementById("SevenElevenDownload");
					// const downloadParent = downloadRoot.parentNode;
					downloadRoot.style.padding = 15;
					// downloadRoot.style.margin = 5;
					// downloadRoot.style.backgroundColor= "red";
					*/

					// approach 2 => convert the div with bg img to Image 
					const barcodeDiv = doc.getElementById("barcodeDiv");
					// only scale on 
					element.scrollHeight > element.scrollWidth ? barcodeDiv.style.width = "100%" : barcodeDiv.style.width = "100%";					
					barcodeDiv.style.backgroundImage = barcodeDiv.style.content; // html2canvas only support background-image					
					barcodeDiv.style["-webkit-background-size"] = "cover";
					barcodeDiv.style["margin-left"] = "15px";
					const imageSrc = barcodeDiv.style.content.match(/url\(\"(.*)\"\)/)?.[1];

					if (imageSrc){
						const imgObj = new Image();	
						imgObj.onload = () =>{
							const {width:targetWidth, height:targetHeight} = barcodeDiv.getBoundingClientRect();
							console.log(`1: ${targetWidth} x ${targetHeight}`);
							imgObj.width = parseInt(targetWidth)/customScale;
							imgObj.height = parseInt(targetHeight)/customScale;

							// console.log(imgObj);
							barcodeDiv.parentNode.insertBefore(imgObj, barcodeDiv);
							barcodeDiv.remove();
							// console.log(barcodeDiv.parentNode);
						};
						imgObj.onerror = (err) => {
							console.error("load image error", err);
						}
						imgObj.src = imageSrc;
					}
				}
			};

			// const vp = doc.getElementById("viewportMeta").getAttribute("content");
			// doc.getElementById("viewportMeta").setAttribute("content", `width=${(element.scrollWidth+30)} initial-scale=${customScale}`);

			html2canvas(element, html2canvasOptions).then(canvas => {
				// element.appendChild(canvas);
				// console.log(canvas);
				let showBlob;

				// handling blurry image
				const context = canvas.getContext('2d');
				context.imageSmoothingEnabled = false;
				context.webkitImageSmoothingEnabled = false;

				canvas.toBlob((blob)=>{
					showBlob = blob
					showFile(showBlob);
					// doc.getElementById("viewportMeta").setAttribute("content", vp);
				},"image/png");
			});
		}
		/* // no longer valid, use iOS version
		else{
			const html2canvasOptions = {
				// allowTaint:false,
				useCORS:true,
				// backgroundColor: "#ffffff",
				foreignObjectRendering: true, // this is need for modern browsers
				windowWidth: element.scrollWidth+30,
				windowHeight:element.scrollHeight+20,
				width:element.scrollWidth+30,
				height:element.scrollHeight+20,
				x:0,
				y:0,
				scrollX:0,
				scrollY:0,
				onclone: (doc)=>{
					const barcodeDivNode = doc.getElementById("barcodeDiv");
					barcodeDivNode.style.width = "95%";
					barcodeDivNode.style["align-self"] = "center";
				},
			};

			html2canvas(element, html2canvasOptions).then(canvas => {
				// element.appendChild(canvas);
				// console.log(canvas);
				let showBlob;

				canvas.toBlob((blob)=>{
					showBlob = blob
					showFile(showBlob);
				},"image/png");
			});
		}
		*/
	}

	async function getBarCode(){
		console.debug(`SevenEleven Effect | generate barcode`);
		try{
			const {qr, text: barcodeText} = await getData(props.accountId);
			
			// Manipulate QR (add 7-11 HSBC, add text)
			// let svgTxt = (new TextDecoder()).decode(Uint8Array.from(qr.data)); // this is using buffer, old implmeentation
			// this is using base64 text
			let svgTxt = atob(qr); // this is using base64 text
			// console.log("svgTxt", svgTxt)

			/////////////////////////////////
			// Add 7-11 HSBC and barcode text to svg
			/////////////////////////////////
			// create HTML object and load in the SVG string
			const svgContainerElem = document.createElement("div");
			svgContainerElem.innerHTML = svgTxt;
			// add height so that there are rooms to add text above and below
			const svgElement = svgContainerElem.querySelector("svg");
			svgElement.setAttribute("height", "280px");
			const originalGElem = svgContainerElem.querySelector("g");

			// push down the barcode
			originalGElem.setAttribute("transform", "translate(0 40)");
			
			// add 7-11 (HSBC) text
			let hsbcSevenElevenTextElement = document.createElementNS('http://www.w3.org/2000/svg','g');
			hsbcSevenElevenTextElement.setAttribute("transform", "translate(0 30)");
			// hsbcSevenElevenTextElement.innerHTML = "<text x='0' y='0'>7-11(HSBC)</text>";
			
			let bacrcodeSevenElevenTextElement2 = document.createElementNS('http://www.w3.org/2000/svg','text');
			bacrcodeSevenElevenTextElement2.textContent = "7-11(HSBC)";
			hsbcSevenElevenTextElement.appendChild(bacrcodeSevenElevenTextElement2);
			
			// originalGElem.parentElement.insertBefore(hsbcSevenElevenTextElement,originalGElem.nextSibling);
			svgElement.insertBefore(hsbcSevenElevenTextElement,originalGElem);

			///////
			// Add barcode text
			///////
			// remove the last HSBC code by using split
			// "3810121050074082641HSBC" => ["3810121050074082641", ""]
			// then take first item and break into groups of 4 char
			// "3810121050074082641" => ["3810", "1210", "5007", "4082", "641"]
			// finally join them with space
			const barcodeTextModified = barcodeText.split("HSBC")[0].match(/.{1,4}/g).join(" ");
			let bacrcodeTextElement = document.createElementNS('http://www.w3.org/2000/svg','g');						
			bacrcodeTextElement.setAttribute("transform", "translate(165 180)");

			// bacrcodeTextElement.innerHTML = `<text>${barcodeTextModified}</text>`;
			let bacrcodeTextElement2 = document.createElementNS('http://www.w3.org/2000/svg','text');
			bacrcodeTextElement2.textContent = barcodeTextModified;
			bacrcodeTextElement.appendChild(bacrcodeTextElement2)

			originalGElem.appendChild(bacrcodeTextElement);
			const svgTextUpdated = svgContainerElem.innerHTML;
			// console.log("svgContainerElem",svgContainerElem);

			// Original implementation
			// ref.current.innerHTML = svgTextUpdated;

			// instead of directly input the svg, make is as bg image so it can scale 
			let svgdataUriStr;

			if (isIE) {
				// Approach 1: using blob => security error (anything from SVG is cross origin...)
				/*
				const svgData = new XMLSerializer().serializeToString(svgElement);
				const blob = new Blob([svgData], {type:"image/svg+xml;charset=utf-8"});
				const text = await (new Response(blob)).text();
				console.log("text", text);
				
				svgdataUriStr = URL.createObjectURL(blob);
				// window.navigator.msSaveOrOpenBlob(blob,"file.ext");
				*/
				
				// Approach 2: using base64 SVG => security error (anything from SVG is cross origin...)
				// svgdataUriStr = `data:image/svg+xml;base64,${btoa(svgTextUpdated)}`;

				// Approach 3: using canvg
				// svgdataUriStr = new XMLSerializer().serializeToString(svgElement);
				svgdataUriStr = svgTextUpdated;
				console.log(svgTextUpdated);
				const canvas = document.createElement('canvas');
				const ctx = canvas.getContext("2d");				
				// double up the scale
				canvas.width=1050;
				canvas.height=600;
				ctx.setTransform(1.95, 0, 0, 1.95, 0, 0);

				// as image itself is trasparent, so we need to add background color
				ctx.rect(0,0,1050,700);
				ctx.fillStyle="white";
				ctx.fill();

				// const canvgProcess = await Canvg.fromString(ctx, svgdataUriStr, {scaleWidth:0.8, scaleHeight:0.8,offsetX:10,offsetY:10});
				// const canvgProcess = await Canvg.fromString(ctx, svgdataUriStr, {ignoreClear:true});
				// const canvgProcess = await Canvg.from(ctx, svgElement, {ignoreClear:true});
				const svgData = new XMLSerializer().serializeToString(svgElement);
				const canvgProcess = await Canvg.fromString(ctx, svgData, {ignoreClear:true, useCORS:false});
				// canvgProcess.start();
				canvgProcess.render();
				
				const bgStr = `url('${canvas.toDataURL()}')`						
				console.log("bgStr",bgStr)
				ref.current.style.backgroundImage = bgStr;
				// ref.current.style.content = bgStr; // fix print => Ie not work when using content
				const blob = canvas.msToBlob("image/png");
				console.log("make Blob");
				setImgBlob(blob);

				// canvgProcess.stop();
			}
			else{
				svgdataUriStr = `data:image/svg+xml;utf8,${encodeURIComponent(svgTextUpdated)}`;

				///////////
				// Prepare download button
				///////////
				// Try to build it into invisiable canvas

				const img = new Image();
				img.crossOrigin = "";
				console.log("load image...")

				img.onerror= (e) => {console.log(e)};
				img.onload = () => {
					// console.log(img);
					console.log("Image load")
					const canvas = document.createElement('canvas');
					const ctx = canvas.getContext("2d");

					// double up the scale
					canvas.width=1050;
					canvas.height=600;				
					ctx.setTransform(1.95, 0, 0, 1.95, 0, 0);

					// as image itself is trasparent, so we need to add background color
					ctx.rect(0,0,1050,600);
					ctx.fillStyle="white";
					ctx.fill();
					
					// draw image on canvas and then output
					ctx.drawImage(img, 25, 0);

					canvas.toBlob((blob)=>{
						setImgBlob(blob);
					},"image/png");					
				}
				img.src=svgdataUriStr;
				const bgStr = `url('${svgdataUriStr}')`;
				// ref.current.style.backgroundImage = bgStr;
				ref.current.style.content = bgStr; // fix print
			}
		}
		catch(err){
			console.error(err);
		}
	};

	// get data and prduce barcode, bind to svg container div to render
	useEffect(()=>{
		getBarCode();

	},[props.accountId])

	return (
		<Grid id="SevenElevenRoot">
			<Grid className={classes.sevenElevenRoot} id="SevenElevenDownload">
				<Typography variant="h1" component="h1">{t('seven_eleven_page_title')}</Typography>
				{/* data[0] because data is a list of accounts */}
				<Grid className={classes.infoGridTableLayout}>
					<InfoGridTableLayout fieldLabelMapping={fieldLabelMapping(i18n.language)} data={props.data[0]}/>
				</Grid>
				<Typography className={classes.reminderText} variant="body1">{t('seven_eleven_page_reminder_text')}</Typography>
				<Grid id="barcodeDiv" className={classes.barcodeDiv} ref={ref} />
			</Grid>
			<Grid className={classes.buttonDivContainer}>
				<Grid>
					<IconButton className={classes.button} disableRipple={true} onClick={props.onClose}>
						<ArrowBackIcon className={classes.buttonIcon} />
					</IconButton>
					<Typography variant="body1">{t('seven_eleven_page_button_back')}</Typography>
				</Grid>
				<Grid>
					<IconButton className={classes.button} disableRipple={true} 
						onClick={download}
					>
						<CloudDownloadIcon className={classes.buttonIcon} />
					</IconButton>
					<Typography variant="body1">{t('seven_eleven_page_button_download')}</Typography>
				</Grid>
				{/* <Grid>
					<IconButton className={classes.button} disableRipple={true} onClick={logout}>
						<ExitToAppIcon className={classes.buttonIcon} />
					</IconButton>
					<Typography variant="body1">{t('seven_eleven_page_button_logout')}</Typography>
				</Grid> */}
			</Grid>
		</Grid>
	)
}
import React, {useState, useCallback, useMemo, useContext, createContext, useEffect} from "react";
import axios from "axios";

const LoginDataContext = createContext();

function useLoginData() {
  const context = useContext(LoginDataContext);
  if (!context) {
    throw new Error(`useLoginData must be used within a LoginDataProvider`)
  }
  return context;
}

function LoginDataProvider(props) {
  const [data, setData] = useState({loggedIn:false}); // empty object
  const [isLoadingLoginApi, setIsLoadingLoginApi] = useState(true);

  useEffect(()=>{
    console.debug(`Login Context Effect | get cms and update state`);

    async function callLogin(){
      if (!data.loggedIn){
        try{
          setIsLoadingLoginApi(true);
          const response = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_ROOT}/isLoggedIn`,
          });
    
          const isLoggedIn = response?.data?.result?.isLoggedIn && window.localStorage.getItem("loggedIn");

          if (isLoggedIn) {
            const accounts = JSON.parse(window.localStorage.getItem("accounts"));

            updateData({ loggedIn: true , accounts: accounts }); // update context for later usage
          }
        }
        catch(err){
          console.error(err);
        }
        finally{
          setIsLoadingLoginApi(false);
        }
      }
    };
    callLogin();
  },[]);

  const updateData = (newData) => {
    console.debug(`In updateData | existing state: ${JSON.stringify(newData)}`);
    console.debug(`In updateData | newData: ${JSON.stringify(newData)}`);
    setData(prevDataState => ({...prevDataState, ...newData}));
  }

  const isLoggedIn = useCallback(() => {
    return data.loggedIn;
  },[data.loggedIn]);

  const logout = useCallback(() => {
    window.localStorage.removeItem("accessToken");
    window.localStorage.removeItem("refreshToken");
    window.localStorage.removeItem("mobileNum");
    window.localStorage.removeItem("licensePlate");
    window.localStorage.removeItem("accounts");
    window.localStorage.removeItem("loggedIn");

    updateData({loggedIn:false});
  },[]);

  const value = useMemo(() => [data, updateData, isLoggedIn, logout, isLoadingLoginApi], [data, isLoggedIn, logout, isLoadingLoginApi])
  return <LoginDataContext.Provider value={value} {...props} />
}

export {LoginDataProvider, useLoginData}
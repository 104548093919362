// import createMuiTheme from '@material-ui/core/styles/createMuiTheme'; // deprecated in 4.12
import createTheme from '@material-ui/core/styles/createTheme';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'

// import responsiveFontSizes from '@material-ui/core/styles/responsiveFontSizes';
// import purple from '@material-ui/core/colors/purple';
// import green from '@material-ui/core/colors/green';

import futuraFontUrl from "../FuturaSBOP-Round.woff2";

const futuraFont = {
  fontFamily: 'FuturaSBOP-Round',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    url(${futuraFontUrl}) format('woff2')
  `,
};

const breakpoints = createBreakpoints({})

// Read this: https://material-ui.com/customization/theming/
//  To override globally on existing classes, read this: https://material-ui.com/customization/globals/
const theme = createTheme({
  palette: {
    primary: {
      main: "#6659B0",
      text: {
        color: "white",
      }
    },
    secondary: {
      main: "#F05282",
      text: {
        color: "black",
      }
    },
  },
  overrides: {
    // Load in the font
    MuiCssBaseline: {
      '@global': {
        '@font-face': [futuraFont],
      },
    },
    MuiTypography:{
      // Standardize the font over the site
      h1:{
        fontSize: "1.6em",
        [breakpoints.up('md')]:{
          fontSize: "2em",
        },
        fontFamily: "FuturaSBOP-Round, Roboto, Helvetica, Arial, sans-serif",
        lineHeight: "1.5em"
      },
      h2:{
        fontSize: "1.5em",
        fontWeight: 600,
        paddingBottom: "0.75rem"
        
      },
      h3:{

      },
      h4:{
        fontSize: "1.2em",
      },
      body1:{
        fontSize: "0.75em",
        [breakpoints.down('xs')]: {
          fontSize: "0.85em",
        },
        /*marginBottom: "0.25em"*/
      }
    },
    // Material Table
    MuiTableSortLabel:{
      root:{
        fontWeight: 500,
        color: "white",
        opacity: 1,
        '&:hover': {
          color: "white",
          weight: 500,
          '& $icon': {
            opacity: 0.5,
          },
        },		
        '&$active':{
          color: "white",
          fontWeight: 600,
          '&& $icon': {
            opacity: 1,
            color: "white",
          },
        },
      }
    },
    MuiTableHead: {
      root: {
        "& .MuiTableSortLabel-root": {
          fontWeight: 600
        }
      }
    }
  },
});

export default theme;
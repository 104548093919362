import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { useTranslation } from 'react-i18next';
// import TermsAndConditions from "./termsAndConditions";
import HtmlContent from '../components/common/htmlContent';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'

// Import custom developed modules
import i18n from '../i18n';
const breakpoints = createBreakpoints({});

const useFooterStyle = makeStyles(theme => ({
	footerRoot: {
		backgroundColor: "#372F48",
		color: "white",
		height: 80,
		width: "100%",
		padding: "0.25rem 1.5rem",
		zIndex: 1100,
	},
	footerWrapper: {
		width: "100%",
		maxWidth: 1220,
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		borderTop: "1px solid #D9D9D9",
		paddingTop: "1rem",
		marginTop: "1rem",
		marginLeft: "auto",
		marginRight: "auto",
		[breakpoints.down('xs')]: {
			marginTop: "0.5rem",
			paddingTop: "0.5rem"
		}
	},
	copyRight: {
		color: "rgba(255, 255, 255, 0.6)"
	},
	termConditionButton: {
		color: "white",
		padding: "0 !important",
		textAlign: "left",
		textTransform: "initial"
	}

}));

function SimpleDialog(props) {
	const classes = useFooterStyle()
	const { onClose, selectedValue, open } = props;
	const { t } = useTranslation("translation", { i18n });

	const handleClose = () => {
		onClose(selectedValue);
	};

	return (
		<Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
			<DialogContent>
				<HtmlContent cmsKey={"terms_and_conditions"} />
			</DialogContent>
		</Dialog>
	);
}

SimpleDialog.propTypes = {
	onClose: PropTypes.func.isRequired,
	open: PropTypes.bool.isRequired,
	// selectedValue: PropTypes.string.isRequired,
};

export default function Footer(props) {
	const classes = useFooterStyle()
	const { t } = useTranslation("translation", { i18n });
	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};
	const handleClose = (value) => {
		setOpen(false);
	};
	return (
		<Grid className={classes.footerRoot}>
			<Grid>
				<Grid className={classes.footerWrapper}>
					<Grid>
						<Typography className={classes.copyRight}>{t('footer_copy_right')}</Typography>
					</Grid>
					<Grid>
						<Button className={classes.termConditionButton} onClick={handleClickOpen}><Typography >{t('footer_terms_condition')}</Typography></Button>
						<SimpleDialog open={open} onClose={handleClose} />
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}

/*


*/
import { useState } from "react";

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';

import _ from "lodash";
// import {DateTime} from "luxon";

import HistoryTable from "./historyTable";
import AccountInfo from "./accountInfo";
import SelectCarpark from "./selectCarpark";

import { useLoginData } from "../contexts/loginContext";
import { useTranslation } from 'react-i18next';

import i18n from '../i18n';

import SevenElevenPage from "./sevenEleven";
import FpsPaymentPage from "./fpsPaymentPage";

import fpsLogo from "../resources/fps_color.svg";
import sevenElevenLogo from "../resources/7-eleven_logo.svg";

const useHistoryContainerStyles = makeStyles((theme) => ({
  root: {
    display:"flex",
		flexDirection:"column",
		alignContent: "center"
  },
	paymentSelect:{
		display: "flex",
		justifyContent: "center",
		// paddingTop: 15,
		"& > .MuiButton-root":{
			padding: "0.8rem 1.2rem",
			margin: "0px 8%",
			borderRadius: ".75rem",
			boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)"
		}
	},
	buttonImg:{
		width:64,
	},
	selectPaymentContainer:{
		textAlign: "center",
	},
	tableContainer:{
		textAlign: "center",
		marginTop: 20,
	}
}));

export default function HistoryContainer(){
	const [loginData] = useLoginData();
	const [selectedAccount, setSelectedAccount] = useState(loginData.accounts?.[0].accountID);
	const [isSevenElevenOpened, setIsSevenElevenOpened] = useState(false);
	const [isFPSOpened, setIsFPSOpened] = useState(false);

	const classes = useHistoryContainerStyles();
	const { t } = useTranslation("translation", {i18n});

	const handleSelectAccountChange = (e) => {
		setSelectedAccount(e.target.value);
	};

	const handleClickSevenEleven = (e) =>{
		setIsSevenElevenOpened(true);
	};

	const handleCloseSevenEleven = (e) =>{
		setIsSevenElevenOpened(false);
	};

	const handleClickFps = (e) =>{
		setIsFPSOpened(true);
	};

	const handleCloseFps = (e) =>{
		setIsFPSOpened(false);
	};

	// Transform data
	const dataList = loginData.accounts.map((accountObj)=>({
		accountId: accountObj.accountID,
		// validToDate: DateTime.fromFormat(accountObj.expiryDate, "yyyy-MM-dd").toFormat("dd MMM yyyy"),
		validToDate: accountObj.expiryDate,	// try to keep yyyy-MM-dd
		licensePlate: accountObj.lpn,
		balance: `$${accountObj.balance.toFixed(2)}`,
		amount: `$${accountObj.monthlyAmount.toFixed(2)}`,
		facilityName: {
			en: accountObj.carparkFacilityNameEn,
			tc: accountObj.carparkFacilityNameTc,
			sc: accountObj.carparkFacilityNameSc
		}
	}));

	// Array to Dict with key = accountId
	const dataDict = _.groupBy(dataList, "accountId");

	return (
		isSevenElevenOpened ? <SevenElevenPage data={dataDict[selectedAccount]} accountId={selectedAccount} onClose={handleCloseSevenEleven}/> : (
		 	isFPSOpened ? <FpsPaymentPage data={dataDict[selectedAccount]} accountId={selectedAccount} onClose={handleCloseFps}/> :
				<Grid className={classes.root}>
					<SelectCarpark data={dataList} handleChange={handleSelectAccountChange} value={selectedAccount}/>
					<AccountInfo data={dataDict[selectedAccount]}/>
					<Grid className={classes.selectPaymentContainer}>
						<Typography variant="h2" component="h2">{t('history_select_payment_title')}</Typography>
						<Grid className={classes.paymentSelect}>
							<Button variant="outlined" onClick={handleClickFps}>
								<img className={classes.buttonImg} src={fpsLogo} alt="FPS"/>
							</Button>
							<Button variant="outlined" onClick={handleClickSevenEleven}>
								<img className={classes.buttonImg} src={sevenElevenLogo} alt="7-11"/>
							</Button>
						</Grid>
					</Grid>
					<Grid className={classes.tableContainer}>
						<HistoryTable accountId={selectedAccount}/>
					</Grid>
				</Grid>
		)
	);
}
import { useEffect, useState, useRef } from "react";
import Typography from "@material-ui/core/Typography";

import { useTranslation } from 'react-i18next';

import i18n from '../../i18n';


/*
	props:
	- logPrefix
	- expireTime
	- count
	- errorMsgKey
	- remainCmsTimeKey
	- expireCmsTimeKey
*/
export default function CountDownMessage(props){
	const { t } = useTranslation("translation", { i18n });
	const [remainSecond, setRemainSecond] = useState(null);
	const timerIdRef = useRef();

	// set count down interval
	useEffect(()=>{
		console.debug(`${props.logPrefix} Effect | set expire message interval`);
		setRemainSecond(props.expireTime); // sometimes the useState init value doesn't set right
		// refresh every second
		timerIdRef.current = setInterval(() => {
			setRemainSecond(remainSecond => remainSecond-1);
		}, 1000);

    // clean up when unmount
    return () => {
      clearInterval(timerIdRef.current);
    };
	}, [props.expireTime, props.count])

	// try clear count down interval (not work yet)
	useEffect(()=>{
		if(remainSecond < 0){
			console.debug(`${props.logPrefix} Effect | clear count down expire message interval`);
			clearInterval(timerIdRef.current);
		}
	}, [remainSecond]);

	return (
		<>
			{ props.errorMsgKey ? <Typography variant="body1" color="error">{t(props.errorMsgKey)}</Typography> :
				( remainSecond ?  
					(remainSecond > 0 ?
						<Typography variant="body1">{t(props.remainCmsTimeKey).replace("{remain}", remainSecond)}</Typography> :
						<Typography variant="body1" color="error">{t(props.expireCmsTimeKey)}</Typography>
					) : null
				)
			}
		</>
	)
}
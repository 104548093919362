import InfoGridTableLayout from "./common/infoGridTableLayout";

// const zip = (a, b) => a.map((k, i) => [k, b[i]]);

const fieldLabelMapping = [
	[
		{
			field: 'accountId',
			label: 'account_info_accountId',
		},
		{
			field: 'validToDate',
			label: 'account_info_valid_to_date',
		}
	],
	[
		{
			field: 'licensePlate',
			label: 'account_info_license_plate',
		},
		{
			field: 'balance',
			label: 'account_info_balance',
		}
	],
	[
		{
			field: 'amount',
			label: 'account_info_amount',
		},
	]
]

/*
	Expected props:
	data:[
		{
			accountId:xxx
			validToDate:xxx
			licensePlate:xxx
			balance:xxx
			amount:xxx
		},
		{...},
		...
	]
*/
export default function AccountInfo(props){
	// data[0] because data is a list of accounts
	return <InfoGridTableLayout fieldLabelMapping={fieldLabelMapping} data={props.data[0]}/>
}
import makeStyles from '@material-ui/core/styles/makeStyles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { useTranslation } from 'react-i18next';

import i18n from '../i18n';


const useSelectCarparkStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function SelectCarpark(props){
	const classes = useSelectCarparkStyles();
	const { t } = useTranslation("translation", {i18n});

	return (
		<>
		<FormControl variant="outlined" className={classes.formControl}>
			<InputLabel>{t('select_carpark_account')}</InputLabel>
			<Select
				labelId="select-carpark_label"
				id="select-carpark"
				value={props.value}
				onChange={props.handleChange}
				label={t('select_carpark_account')}
			>
				{/* Do not include a not selected */}
				{/* <MenuItem value=""> 
					<em>{t('select_no_carpark_selected')}</em>
				</MenuItem> */}
				{props.data.map((item)=>(
					(<MenuItem key={item.accountId} value={item.accountId}>{item.facilityName[i18n.language]}</MenuItem>)
				))}
			</Select>
		</FormControl>
		</>
	)
}
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from "@material-ui/core/Button";

import { useTranslation } from 'react-i18next';
import i18n from "../i18n";

const useChangeLanguageStyle = makeStyles(theme => ({
	buttons:{
		width:18,
		fontSize: "1rem",
		minWidth: 48
	},
}));

const changeLangFn = (langCode)=>{
	i18n.changeLanguage(langCode);
}

const ChangeLanguage = (props) =>{
	const classes = useChangeLanguageStyle();
	useTranslation("translation", { i18n });

	return (
		<>
			{i18n.language!=="en" ? <Button className={classes.buttons} color="primary" onClick={(e)=>{changeLangFn("en")}}>EN</Button> : null}
			{i18n.language!=="tc" ? <Button className={classes.buttons} color="primary" onClick={(e)=>{changeLangFn("tc")}}>繁</Button> : null}
			{i18n.language!=="sc" ? <Button className={classes.buttons} color="primary" onClick={(e)=>{changeLangFn("sc")}}>簡</Button> : null}
		</>
	)
}

export default ChangeLanguage;
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';

export default function HtmlContent(props){
	const { t } = useTranslation("translation", { i18n });
	const [html, setHtml] = useState({__html:""});

	useEffect(()=>{
		console.debug(`HtmlContent Effect | load in CMS into inner html`);
		// const cmsContent = JSON.parse(window.localStorage.getItem("cms"));
		
		// if (cmsContent?.[i18n.language]?.[props.cmsKey]){
		// 	setHtml({__html:cmsContent[i18n.language][props.cmsKey]});
		// }

		setHtml({__html:t(props.cmsKey)});
		
	}, [i18n.language, props.cmsKey, t(props.cmsKey)])
	

	return (
		<div dangerouslySetInnerHTML={html} />
	)
}
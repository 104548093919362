import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';

import {JSONPath} from 'jsonpath-plus';
import { useTranslation } from 'react-i18next';

import i18n from '../../i18n';

const useInfoGridTableStyles = makeStyles((theme) => ({
  infoGridRoot: {
	  	fontSize: "1rem",
	  	lineHeight: 1,
		marginTop: 15,
		marginBottom: 15,
		"& .MuiGrid-item":{
			padding:3,
		},
		
  },
	gridValue:{
		fontWeight: 600,
	}
}));

/*
	props:
	 - fieldLabelMapping => 2D array, e.g.
	 [
		[
			{
				field: 'accountId',
				label: 'account_info_accountId',
			},
			{
				field: 'validToDate',
				label: 'account_info_valid_to_date',
			}
		],
		[
			{
				field: 'licensePlate',
				label: 'account_info_license_plate',
			},
			{
				field: 'balance',
				label: 'account_info_balance',
			}
		],
		[
			{
				field: 'amount',
				label: 'account_info_amount',
			},
		]
	]

	 - data: Object like
	 {
			accountId : "3343243",
			validToDate : "2021-07-13",
			licensePlate : "dsad",
			balance: 234,
			amount: 34324
	 }
*/
export default function InfoGridTableLayout(props){
	const classes = useInfoGridTableStyles();
	const { t } = useTranslation("translation", {i18n});

  return (
    <Grid className={classes.infoGridRoot}>
			{props.fieldLabelMapping.map((row, rowIdx) => {
				return (					
					<Grid key={rowIdx} container item xs={12} spacing={0} >
						{row.map((pair, pairIdx) => {
							return (
								<Grid key={pairIdx} container item xs={12/props.fieldLabelMapping[0].length}>
										<Grid item xs={12} md={6}>
											{`${t(pair.label)}:`}
										</Grid>
										<Grid item xs={12} md={6} className={classes.gridValue}>
											{/* {props.data[pair.field]} */}
											{JSONPath({path: pair.field, json: props.data})[0]}
										</Grid>
								</Grid>)
						})}
					</Grid>
				)
			})
			}
    </Grid>
  );
}
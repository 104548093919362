import Grid from '@material-ui/core/Grid';
import HtmlContent from '../components/common/htmlContent';

import makeStyles from '@material-ui/core/styles/makeStyles';

const useTermsAndConditionsStyles = makeStyles((theme) => ({
	// common
	termsAndConditionsRoot: {
		marginTop: 20,
		marginBottom: 20,
		borderWidth: 1,
		borderStyle: "solid",
		borderColor: "gray",
		padding: "0.5rem 0.75rem"
	}
}));

export default function TermsAndConditions(props) {
	const classes = useTermsAndConditionsStyles()
	return (
		<Grid className={classes.termsAndConditionsRoot}>
			<HtmlContent cmsKey={"terms_and_conditions"}/>
		</Grid>
	)
}
import { useCallback, useEffect, useRef, useState } from "react";
import makeStyles from '@material-ui/core/styles/makeStyles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import PaymentIcon from '@material-ui/icons/Payment';

import axios from "axios";

import { useTranslation } from 'react-i18next';

import i18n from '../i18n';

import { useLoginData } from "../contexts/loginContext";

import InfoGridTableLayout from "./common/infoGridTableLayout";
import FpsSteps from "./fpsStepsInstruction";
import FpsPaymentCompletePage from "./fpsPaymentCompletePage";
import CountDownMessage from "./common/countDownMessage";

const breakpoints = createBreakpoints({});

const fieldLabelMapping = (lang) => ([
	[
		{
			field: `facilityName.${lang}`,
			label: 'fps_payment_qr_page_carpark_name_label',
		}
	],
	[
		{
			field: 'licensePlate',
			label: 'fps_payment_qr_page_license_plate_label',
		}
	],
	[
		{
			field: 'payAmountStr',
			label: 'fps_payment_qr_page_amount_label',
		}
	]
]);

const usePaymentQrPageStyles = makeStyles(theme => ({
	fpsQrPageRoot:{
		display:"flex",
		flexDirection:"column",
		alignContent:"center",
		"& > .MuiTypography-root":{
			textAlign:"center",
		},
	},
	infoGridTableLayout:{
		width: "80%",
		alignSelf: "flex-end",
		marginLeft: "auto",
		marginRight: "auto",
		maxWidth: "400px",
		[breakpoints.down('sm')]: {
			textAlign: "center"
		},
	},
	button:{		
		margin: 10,
		color: "black",
		marginBottom: 0
	},
	buttonIcon:{
		// should use mediaQuery
		fontSize: "2em"
	},
	buttonIconPayment: {
		fontSize: "2em",
		color: 'red'
	},
	qrcodeDiv:	{
		height: "calc(100vw * 0.5)",
		alignSelf:"center",
		width: "55%",
		backgroundRepeat:"no-repeat",
		backgroundSize: "cover",
		marginLeft: "auto",
		marginRight: "auto",
		"@media (min-width: 767px)": { //tablet
			width: "35%",
			height: "28vw",
		},
		"@media (min-width: 1224px)": { //desktop or laptop
			width: "20vw",
			height: "20vw",
		},
	},
	buttonDivContainer: {
		display: "flex",
		justifyContent: "center",
		"& > .MuiGrid-root":{
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
		},
	},
	reminderText: {
		[breakpoints.down('sm')]:{
			fontSize: "initial"
		}
	}
}));

const showFile = (blob) => {
  // It is necessary to create a new blob object with mime-type explicitly set
  // otherwise only Chrome works like it should
  var newBlob = new Blob([blob], {type: "image/png"})

  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob);
    return;
  }

  // For other browsers: 
  // Create a link pointing to the ObjectURL containing the blob.
  const data = window.URL.createObjectURL(newBlob);

  var link = document.createElement('a');
  link.href = data;
  link.download="barcode.png";
  link.click();
  setTimeout(function(){
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(data);
  }, 100);
}

/*
	props:
	- expireTime
	- count
	- errorMsgKey
*/
const FpsCountDownMessage = (props) => {
	return (
		<CountDownMessage 
			{...props}
			logPrefix="FPS Count Down Message"
			remainCmsTimeKey="fps_payment_qr_page_remaining_time"
			expireCmsTimeKey="fps_payment_qr_page_expired"
		/>
	)
}

/*
	props:
		accountId		
		onClose - function
*/
export default function FpsPaymentQrPage(props){
	const ref = useRef(null);
	const [imgBlob, setImgBlob] = useState(null);
	const [txnRef, setTxnRef] = useState(null);
	const [openTransactionPage, setOpenTransactionPage] = useState(false);
	const [fpsExpireTime, setFpsExpireTime] = useState(null);
	const [fpsCount, setFpsCount] = useState(0);
	const [errorMsgKey, setErrorMsgKey] = useState("");
	const timerIdRef = useRef();
	
	const classes = usePaymentQrPageStyles();
	const { t } = useTranslation("translation", {i18n});

	const [, , , logout ] = useLoginData();
	const isIE = window.navigator && window.navigator.msSaveOrOpenBlob;

	///////////////////////
	// utils functions
	///////////////////////
	const download = () => {
		showFile(imgBlob);
	}

	// call API to get the HSBC FPS QR code
	const getQrData = async ()=>{
		const accountId = props.accountId;
		const amount = props.data[0].payAmount;
		let result;

		const postData = {
			accountNum: accountId,
			amount: amount
		};

		try{
			const response = await axios({
				method: "post",
				url: `${process.env.REACT_APP_API_ROOT}/createPayment`,
				data: postData,
			});

			if (response?.data?.result?.qr){
				result = response.data.result;
				const delta = Math.floor((result.actualExpireTime - (new Date()).getTime())/1000);
				setFpsCount(prev => prev+1);
				setFpsExpireTime(delta);
				setErrorMsgKey(null);
				setTxnRef(result.txnRef);
			}
			return Promise.resolve(result);
		}
		catch(err){
			console.error(err);
			setErrorMsgKey("error_payment_qr_unknown");
			return Promise.reject(err);
		}
	}

	// call API to get the HSBC payment status
	const getStatusData = async ()=>{
		let result;

		const postData = {
			txnRef: txnRef
		};

		try{
			const response = await axios({
				method: "post",
				url: `${process.env.REACT_APP_API_ROOT}/getPaymentStatus`,
				data: postData,
			});
			
			if (response?.data?.result?.proCode){
				result = response.data.result.proCode;
			}
			return Promise.resolve(result);
		}
		catch(err){
			console.error(err);
			return Promise.reject(err);
		}
	}

	// get the return from getQrData() [the QR code] and then put to Canvas (for export)
	const makeSvg = async () => {
		console.debug(`FpsPaymentQr Effect | generate QR code`);
		// use data[0] as data is any array of length 1, which need to align with InfoGridTableLayout expectation
		try{
			const {qr} = await getQrData();
		
			const svgdataUriStr = qr;
			const bgStr = `url('${svgdataUriStr}')`;
			// console.log(bgStr);
			//ref.current.style.backgroundImage = bgStr;
			ref.current.style.content = bgStr; // fix print

			///////////
			// Prepare download button
			///////////
			// Try to build it into invisiable canvas
			const img = new Image();

			img.onload = () => {
				// console.log(img);
				const canvas = document.createElement('canvas');
				const ctx = canvas.getContext("2d");

				// // half up the scale ?
				canvas.width=1000;
				canvas.height=1000;
				// ctx.setTransform(0.5, 0, 0, 0.5, 0, 0);

				// as image itself is trasparent, so we need to add background color
				ctx.rect(0,0,1000,1000);
				ctx.fillStyle="white";
				ctx.fill();
				
				// draw image on canvas and then output
				ctx.drawImage(img, 25, 25);
				if(isIE){
					const blob = canvas.msToBlob("image/png");
					setImgBlob(blob);
				}
				else{
					canvas.toBlob((blob)=>{
						setImgBlob(blob);
					},"image/png");
				}
			}
			img.src=svgdataUriStr;
		}
		catch(err){
			console.error(err);
			setErrorMsgKey("error_payment_qr_unknown");
		}
	}

	// this would be removeid later when we don't need to simulate
	const simulatePayment = async () => {
		let result;

		const amount = props.data[0].payAmount;

		const postData = {
			txnRef: txnRef,
			amount: amount
		};

		try{
			const response = await axios({
				method: "post",
				url: `${process.env.REACT_APP_API_ROOT}/simulatePayment`,
				data: postData,
			});
			
			if (response?.data?.result){
				result = response.data.result;
			}

			return Promise.resolve(result);
		}
		catch(err){
			console.error(err);
			return Promise.reject(err);
		}		
	}

	// Effect for creating setInterval 
	useEffect(() => {
		console.debug(`FPS Payment QR Page Effect | enter effect`);
		if (txnRef){
			console.debug(`FPS Payment QR Page Effect | set check payment status interval`);
			timerIdRef.current = setInterval(() => {
				getStatusData()
					.then(statusResult => {
						if (statusResult.trim() !== "000000"){ // anything NOT success
							// continue
						}
						else { //code = 000000 => success
							console.debug(`FPS Payment QR Page Effect | clear interval ${timerIdRef.current}`);
							clearInterval(timerIdRef.current);
							setOpenTransactionPage(true);
						}
					})
					.catch(err=>{console.error(err)});
			}, (process.env.REACT_APP_PAYMENT_GET_STATUS_INTERVAL || 10) * 1000);
			
			console.log(`refresh timer id: ${timerIdRef.current}`);
		}
		else {
			// pass?
		}

		// clean up when unmount
		return () => {
			console.debug(`FPS Payment QR Page Effect | return - clear interval ${timerIdRef.current}`);
			clearInterval(timerIdRef.current);
		};
	},[txnRef]);

	// get data and produce barcode, bind to svg container div to render
	useEffect(()=>{
		makeSvg();
	},[props.accountId])

	return (
		// (onClose should be passed from PS Payment page root so this would go back to History page)
		openTransactionPage ? <FpsPaymentCompletePage onClose={props.onBackToHistory} data={props.data[0]} txnRef={txnRef} amount={props.data[0].payAmount}/> : (
		<Grid className={classes.fpsQrPageRoot}>
			<Typography variant="h2" component="h1">{t('fps_payment_qr_page_title')}</Typography>
			<Grid className={classes.infoGridTableLayout}>
				<InfoGridTableLayout fieldLabelMapping={fieldLabelMapping(i18n.language)} data={props.data[0]}/>
			</Grid>
			<Typography className={classes.reminderText} variant="body1">{t('fps_payment_qr_page_reminder_text')}</Typography>
			<Grid className={classes.qrcodeDiv} ref={ref} />
			<FpsCountDownMessage 
				expireTime={fpsExpireTime} 
				count={fpsCount}
				errorMsgKey={errorMsgKey}
			/>
			<Grid className={classes.buttonDivContainer}>
				<Grid>
					<IconButton className={classes.button} disableRipple={true} onClick={props.onClose}>
						<ArrowBackIcon className={classes.buttonIcon} />
					</IconButton>
					<Typography variant="body1">{t('fps_payment_qr_page_button_back')}</Typography>
				</Grid>
				<Grid>
					<IconButton className={classes.button} disableRipple={true}
						onClick={makeSvg}
					>
						<AutorenewIcon className={classes.buttonIcon}/>
					</IconButton>
					<Typography variant="body1">{t('fps_payment_qr_page_button_refresh')}</Typography>
				</Grid>
				{/* <Grid>
					<IconButton className={classes.button} disableRipple={true} 
						onClick={download}
					>
						<CloudDownloadIcon className={classes.buttonIcon} />
					</IconButton>
					<Typography variant="body1">{t('fps_payment_qr_page_button_download')}</Typography>
				</Grid> */}
				{/* <Grid>
					<IconButton className={classes.button} disableRipple={true} onClick={logout}>
						<ExitToAppIcon className={classes.buttonIcon} />
					</IconButton>
					<Typography variant="body1">{t('fps_payment_qr_page_button_logout')}</Typography>
				</Grid> */}
				{process.env.REACT_APP_USE_SIM_PAYMENT ? 
				<Grid>
					<IconButton className={classes.button} disableRipple={true} onClick={simulatePayment}>
						<PaymentIcon className={classes.buttonIconPayment} />
					</IconButton>
					<Typography variant="body1">{t('fps_dummy_pay_confirm')}</Typography>
				</Grid> : null}
			</Grid>
			<FpsSteps/>
		</Grid> )
	)
}
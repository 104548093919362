import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const languageDetector = new LanguageDetector();

function customMapping(lang) {
  // specific sub lang
  if (lang.toLowerCase() === 'zh-cn' || lang.toLowerCase() === 'zh-sg' ){
    return 'sc';
  }
  // major lang (including zh-hk, zh-whatever)
  else if (lang.substring(0,2).toLowerCase() === 'zh'){
    return 'tc';
  }
  else {
    return 'en';
  }
}

const customNavigator = {
  name: 'customNavigator',

  lookup(options) {
    let found = [];

    if (typeof navigator !== 'undefined') {
      if (navigator.languages) { // chrome only; not an array, so can't use .push.apply instead of iterating
        for (let i=0; i < navigator.languages.length; i++) {
          found.push(navigator.languages[i]);
        }
      }
      if (navigator.userLanguage) {
        found.push(navigator.userLanguage);
      }
      if (navigator.language) {
        found.push(navigator.language);
      }
    }

    return found.length > 0 ? found.map(lang => customMapping(lang)) : undefined;
  }
};
languageDetector.addDetector(customNavigator)


i18n
  //.use(Backend)
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    //fallbackLng: 'en',
		debug: false,
    detection: {
      order: ['localStorage', 'customNavigator'],
      lookupLocalStorage: 'i18nextLng',
      caches: ['localStorage']
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
		},

		resources: {}	
  });


export default i18n;
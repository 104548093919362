import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

/*
	For adding auth header
	Configure this at entry file index.js here to allow all axios call to respect this
*/
import axios from 'axios';

/*
  Get accessCode from localstorage and put it into request header
*/
async function injectHeader(req){
	const accessToken = window.localStorage.getItem('accessToken');
	const mobileNum = window.localStorage.getItem('mobileNum');
	const licensePlate = window.localStorage.getItem('licensePlate');
  // console.debug(`In injectHeader | accessToken is ${accessToken}`)
	
	req.headers = {
		...req.headers,
		accessToken:accessToken,
		mobileNum:mobileNum,
		licensePlate:licensePlate
	}
	return req;
}

axios.interceptors.request.use(async (req) =>{
  return Promise.resolve(injectHeader(req));
});

// HTTPS redirect
// console.debug(`process.env: ${JSON.stringify(process.env)}`)
// console.debug(`At root | use HTTPS redirect: ${process.env.REACT_APP_USE_HTTPS_REDIRECT}`);
if (process.env.REACT_APP_USE_HTTPS_REDIRECT !== 'false'){	
	if (document.location.protocol === "http:"){
		window.location.href=window.location.href.replace("http://","https://");
	}
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Import React related
import { useEffect, useRef } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import CssBaseline from "@material-ui/core/CssBaseline";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import makeStyles from '@material-ui/core/styles/makeStyles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'

// Import other modules
import axios from "axios";
import _ from "lodash";
import { useIdleTimer } from 'react-idle-timer'

// Import custom developed modules
import i18n from "./i18n";
import theme from "./themes/theme";
import { CmsDataProvider, useCmsData } from "./contexts/cmsContext";
import { LoginDataProvider, useLoginData } from "./contexts/loginContext";
// import {NotificationDataProvider, useNotificationData} from "./contexts/notificationContext";
import Login from "./components/login";
import TopBar from "./components/topBar";
import Footer from "./components/footer";
import HistoryContainer from "./components/historyContainer";
// import Notifications from "./components/Notifications";
import PreLoadingSkeleton from "./components/preloading";

// import preLoginCmsJson from "./tools/tmp.json";
const breakpoints = createBreakpoints({})

const useAppMainStyle = makeStyles(theme => ({
	container:{
    display:"flex",
    flexDirection:"column",
    height: "100vh",
    "@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)" : {
      display: "block"
    }
	},
  contentContainer:{    
    width: "calc(80% - 20px)",
    maxWidth: 1024,
    alignSelf: "center",
    flex:1, // push the footer to bottom (this content is before footer)
    paddingBottom: 20,
    marginTop: "1rem",
    marginLeft: "auto",
    marginRight: "auto",
    [breakpoints.down('sm')]: {
			width: "calc(85% - 15px)",
    },
    [breakpoints.down('xs')]: {
			width: "calc(90% - 15px)",
    },
    "@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)" : {
      marginTop: 82,
      minHeight: "calc(100vh - 150px)"
    }
  },
}));

/*
  Note: this is same code as handleRefresh in Login, except hardcoding mobileNum and license plate
  [TODO] To be summarize into a common function, and seperate into another file
*/
const refreshToken = async (isLoggedIn) => {
  if (isLoggedIn){
    console.debug(`Refresh token | is logged in`);
    const postData = {
      mobileNum: window.localStorage.getItem('mobileNum'),
      licensePlate: window.localStorage.getItem('licensePlate'),
      token: window.localStorage.getItem("refreshToken"),
    };

    const result = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_ROOT}/refreshToken`,
      data: postData,
    });

    // console.debug(`In refreshToken | result is ${JSON.stringify(result)}`);
    if (result?.data?.result){
      const {accessToken, refreshToken} = result?.data?.result;

      window.localStorage.setItem("accessToken", accessToken);
      window.localStorage.setItem("refreshToken", refreshToken);
    }
  }
  else{
    console.debug(`Refresh token | NOT logged in`);
  }
}

/*
  1. Get CMS version from API
  2. put into localstorage
*/
// Old implementation
/*
const getCms = async () => {
  let upToDateVersion;
  let cmsVersionNotMatch = false;

  // 1. Get CMS version from API
  const cmsVersionResult = await axios({
    method: "get",
    url: `${process.env.REACT_APP_API_ROOT}/cms_version`,
  });

  // 2. Compare with cached version number
  if (cmsVersionResult?.data?.result) {
    upToDateVersion = cmsVersionResult.data.result;
    const cachedVersion = window.localStorage.getItem("cmsVersion");
    if (!cachedVersion || upToDateVersion !== cachedVersion) { // !cachedVersion when first time hitting the site
      console.debug(`In getCms | need to update | version cached: ${cachedVersion} | current version: ${upToDateVersion}`);
      cmsVersionNotMatch = true;
    }
  }

  // 3. Update CMS data if version number not matched or never cached
  if (cmsVersionNotMatch || !window.localStorage.getItem("cms")) { // version is old or first time with no cache
    const cmsResult = await axios({
      method: "get",
      url: `${process.env.REACT_APP_API_ROOT}/cms`,
    });

    if (cmsResult?.data?.result) {
      console.debug(`In getCms | update cms data and version in cache`);

      // set version and cms data to localstorage
      window.localStorage.setItem("cmsVersion", upToDateVersion);
      window.localStorage.setItem("cms", JSON.stringify(cmsResult.data.result));
    }
  }
};
*/

const convertCmsData = (htmls, labels) => {
  // Transform Data:
    // Because cms return like follow:
    // {
    //   htmls: [{title:xxx, lang:"en", ...}, ...],
    //   labels: [{...}, ...],
    // }

    // merge .htmls and .labels into 1 dict first
    const mergedCms = [ ...htmls, ...labels ];

    // Need to transform it by grouping into: {en: {k:v, ...}, tc: {...}, sc: {...} }
    const groupedCms = _.groupBy(mergedCms, (item) => {
      // group by use a function that return grouping value, now use lang as groupping value
      return item.lang;
    });

    // finally group by title to convert the arrays to dict, _mapValues is a map for dict per key and return object
    const processedCms = _.mapValues(groupedCms, (langObj) => _.mapValues((_.keyBy(langObj, 'title')), (item => (item.value))));

    // for the ease of coding - change which intermediate value above for next process
    const processedCmsData = processedCms;
    return processedCmsData;
};

const getCmsFromApi = async () => {
  const cmsResult = await axios({
    method: "get",
    url: `${process.env.REACT_APP_API_ROOT}/cms`,
  });

  if (cmsResult?.data?.result) {
    console.debug(`In getCmsFromApi | update cms data in localstorage`);

    /*
    // Transform Data:
    // Because cms return like follow:
    // {
    //   htmls: [{title:xxx, lang:"en", ...}, ...],
    //   labels: [{...}, ...],
    // }

    // merge .htmls and .labels into 1 dict first
    const mergedCms = [ ...cmsResult.data.result.htmls, ...cmsResult.data.result.labels ];

    // Need to transform it by grouping into: {en: {k:v, ...}, tc: {...}, sc: {...} }
    const groupedCms = _.groupBy(mergedCms, (item) => {
      // group by use a function that return grouping value, now use lang as groupping value
      return item.lang;
    });

    // finally group by title to convert the arrays to dict, _mapValues is a map for dict per key and return object
    const processedCms = _.mapValues(groupedCms, (langObj) => _.mapValues((_.keyBy(langObj, 'title')), (item => (item.value))));

    // for the ease of coding - change which intermediate value above for next process
    const processedCmsData = processedCms;

    // console.debug(`In getCmsFromApi | processedCmsData is : ${JSON.stringify(processedCmsData)}`)
    */
    const processedCmsData = convertCmsData(cmsResult.data.result.htmls, cmsResult.data.result.labels);
    // set cms data to localstorage
    window.localStorage.setItem("cms", JSON.stringify(processedCmsData));
  }

  return window.localStorage.getItem("cms");
};

/*
  Because CMS API call require login success the very first time, this is to prepare minimum info that is before login  
*/
/* // no longer used
const setMinimalCmsBeforeLogin = () => {
  //[TODO]: add  use local storage of previous load
  const cmsCached = window.localStorage.getItem("cms");
  if (cmsCached) {
    const cmsCachedJson = JSON.parse(cmsCached);
    i18n.addResourceBundle("en", 'translation', cmsCachedJson.en, true, true);
    i18n.addResourceBundle("tc", 'translation', cmsCachedJson.tc, true, true);
    i18n.addResourceBundle("sc", 'translation', cmsCachedJson.sc, true, true);
  }
  else {   
    // Fall back => hard code if init load and not log in before
    // console.log(preLoginCmsJson);
    const processedCms = convertCmsData(preLoginCmsJson.CarParkPaymentMiscellaneous, preLoginCmsJson.CarParkPaymentPageResource)
    i18n.addResourceBundle("en", 'translation', processedCms.en, true, true);
    i18n.addResourceBundle("tc", 'translation', processedCms.tc, true, true);
    i18n.addResourceBundle("sc", 'translation', processedCms.sc, true, true);
  }
}
*/

function App() {
  return (
    <>
      {/* <NotificationDataProvider> */}
        <LoginDataProvider>
          <CmsDataProvider>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <AppMain />
            </ThemeProvider>
          </CmsDataProvider>
        </LoginDataProvider>
      {/* </NotificationDataProvider> */}
    </>
  );
}

function AppMain() {
  const classes = useAppMainStyle();
  const [cmsData, updateCmsData] = useCmsData();
  const [data, , isLoggedIn, logout, isLoadingLoginApi ] = useLoginData();
  // const [, setNotificationMsgs] = useNotificationData();
  const timerIdRef = useRef();

  //////////////////////////////
  //  Effects
  //////////////////////////////

  // set basic CMS before login success - disabled
  // useEffect(() => {
  //   console.debug(`AppMain Effect | set initial cms resources`);
  //   setMinimalCmsBeforeLogin();
  // }, [])

  // refresh token (update whenever is logged in state change)
  useEffect(() => {
    console.debug(`AppMain Effect | set refresh token interval`);
    console.debug(`refresh token timer outside interval isLoggedIn() | ${isLoggedIn()}`);
    timerIdRef.current = setInterval(() => {
      // console.debug(`refresh token timer isLoggedIn() | ${isLoggedIn()}`);
      // console.debug(`refresh token timer data | ${JSON.stringify(data)}`);
      refreshToken(isLoggedIn());
    }, process.env.REACT_APP_REFRESH_TOKEN_INTERVAL * 1000);
    // }, 10 * 1000); // for debug
    console.log(`refreshTimer: ${timerIdRef.current}`);

    // clean up when unmount
    return () => {
      clearInterval(timerIdRef.current);
    };
  }, [data.loggedIn]);

  // get CMS from API call, store to localstorage as well as put into context
  // [Important] Note that we should load CMS AFTER login again as CMS API need auth
  useEffect(() => {
    // if (isLoggedIn()){ // allow CMS to load upon page load
      console.debug(`AppMain Effect | get cms and update state`);
      getCmsFromApi().then((strCms) => {
        // this would change cmsData and then trigger effect below to load cmsData into i18n
        const cmsJsonData = JSON.parse(strCms);
        window.localStorage.setItem("cms", strCms);
        updateCmsData(cmsJsonData);
      });
    // } // allow CMS to load upon page load
  // }, [data.loggedIn]) // allow CMS to load upon page load
  }, []) // allow CMS to load upon page load

  // prepare i18n => load CMS info into i18n, it would overwrite setMinimalCmsBeforeLogin() resources
  useEffect(() => {
    console.debug(`AppMain Effect | update i18n after cms data update`);
    i18n.addResourceBundle("en", 'translation', cmsData.en, true, true);
    i18n.addResourceBundle("tc", 'translation', cmsData.tc, true, true);
    i18n.addResourceBundle("sc", 'translation', cmsData.sc, true, true);

    // force change lanage to take effect on new updates
    i18n.changeLanguage(i18n.language);
    console.debug(`Language as ${i18n.language}`)
  }, [cmsData]);

  // Test notification
  // useEffect(()=>{
  //   console.debug("this is a test snack")
  //   setNotificationMsgs( ['this is a test', "hihi"]);
  // },[]);

  //////////////////////////////
  //  Idle timer
  //////////////////////////////
  const handleOnIdle = event => {
    // console.log('user is idle', event)
    // console.log('last active', getLastActiveTime())
    console.log(`user is idle for ${process.env.REACT_APP_IDLE_TIMER_INTERVAL} mins, auto logout triggered`);
    // setNotificationMsgs(['logout']);
    logout();
  }

  // const handleOnActive = event => {
  //   console.log('user is active', event)
  //   console.log('time remaining', getRemainingTime())
  // }

  // const handleOnAction = event => {
  //   console.log('user did something', event)
  // }

  // const { getRemainingTime, getLastActiveTime } = useIdleTimer({
  useIdleTimer({
    timeout: 1000 * 60 * process.env.REACT_APP_IDLE_TIMER_INTERVAL, // REACT_APP_IDLE_TIMER_INTERVAL is in min, timeout is in ms
    onIdle: handleOnIdle,
    // onActive: handleOnActive,
    // onAction: handleOnAction,
    debounce: 500
  })
  
  // console.log("Render AppMain");
  // console.log(`isLoadingLoginApi: ${isLoadingLoginApi} | isLoggedIn(): ${isLoggedIn()}`);
  //////////////////////////////
  //  Render
  //////////////////////////////
  return (
    <Grid className={classes.container}>
      <TopBar />
      <Box m={5}/>
        {/* <Notifications /> */}
        <Grid className={classes.contentContainer}>
        {
          isLoadingLoginApi ? <PreLoadingSkeleton />: (
            // !loginData.loggedIn ? 
            !isLoggedIn() ?
              <Login /> : 
              <HistoryContainer/>
          )
        }
        </Grid>
      <Footer />
    </Grid>
  )
}

export default App;
